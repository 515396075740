import { render, staticRenderFns } from "./WidgetProjectSummary.vue?vue&type=template&id=7bf57acc&scoped=true"
import script from "./WidgetProjectSummary.vue?vue&type=script&lang=js"
export * from "./WidgetProjectSummary.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bf57acc",
  null
  
)

export default component.exports