<template>
  <v-dialog v-model="dialog" persistent max-width="1600px" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card
      :loading="loading"
    >
      <v-card-title>
        <span class="headline">Beitrag erstellen 
          <template v-if="selectedPlan.assigned_date">
            für den 
            {{ new Date(selectedPlan.assigned_date).toLocaleDateString("en-CH")  }}
          </template>
        </span>
      </v-card-title>

      <v-card-subtitle>
        mit * gekennzeichnete Felder sind Pflichtfelder.
      </v-card-subtitle>

      <v-tabs
        v-if="instanceData.hasSongs"
        v-model="tab"
        align-with-title
      >
        <v-tab>
          Eintrag
        </v-tab>
        <v-tab>
          Liederbuch
          <v-icon v-if="used_song_text">mdi-check</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation>
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" sm="7">
                    <span class="form-header">Inhalt</span>
                    <v-divider class="mb-3"/>
    
                    <v-row>
                      <v-col cols="12">
                        <template v-if="selectedPlan.content_topic_data">
                          <span class="text-h6">
                            Arbeitstitel "{{ selectedPlan.content_topic_data.name }}"
                          </span>
                        </template>
                        <v-text-field
                            v-model="form_title"
                            :rules="[v => !!v || 'Titel ist ein Pflichtfeld']"
                            label="Titel*"
                            required
                            filled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <span class="form-header">Beitragstext*</span>
                        <tiptap-vuetify
                            @init="onInitTiptap"
                            :card-props="{ flat: true, color: '#d8dad7', 'min-height': 300 }"
                            :toolbar-attributes="{ color: 'primary', dark: true }"
                            v-model="form_text"
                            :extensions="extensions"
                        />
                        <small :class="this.htmlToText(form_text).length > instanceData.articleTextLength ? 'text--warn' : ''">{{
                            this.htmlToText(form_text).length
                          }}/{{instanceData.articleTextLength}}</small>
                      </v-col>
    
                      <v-col cols="12"
                      v-if="instanceData.articleQuestionField"
                      >
                        <span class="form-header">Frage</span>
                        <tiptap-vuetify
                            @init="onInitQuestionTiptap"
                            :card-props="{ flat: true, color: '#d8dad7' }"
                            :toolbar-attributes="{ color: 'primary', dark: true }"
                            v-model="form_question"
                            :extensions="questionHintExtensions"
                        />
                      </v-col>
                          
                      <v-col cols="12"
                      v-if="instanceData.articleHintField"
                      >
                        <span class="form-header">Tipp</span>
                        <tiptap-vuetify
                            @init="onInitHintTiptap"
                            :card-props="{ flat: true, color: '#d8dad7' }"
                            :toolbar-attributes="{ color: 'primary', dark: true }"
                            v-model="form_hint"
                            :extensions="questionHintExtensions"
                        />
                      </v-col>
    
                      <v-col cols="12">
                        <v-text-field
                            v-if="!instanceData.hasReadingPlan && instanceData.recommendedBibleRefInArticlePlannings"
                            disabled
                            v-model="form_recommended_bible_ref"
                            label="Bibellesestelle"
                            filled
                        ></v-text-field>
                        <v-text-field
                            v-else-if="!instanceData.hasReadingPlan"
                            v-model="form_recommended_bible_ref"
                            label="Bibellesestelle"
                            filled
                        ></v-text-field>
                        <v-text-field
                            v-else
                            :disabled="readingPlan.recommended_bible_reference && readingPlan.recommended_bible_reference !== ''"
                            v-model="form_recommended_bible_ref"
                            label="Bibellesestelle"
                            filled
                        ></v-text-field>
                        <v-text-field
                            v-if="instanceData.hasArticlePlan"
                            :disabled="selectedPlan.learning_verse && selectedPlan.learning_verse !== ''"
                            v-model="selectedPlan.learning_verse"
                            label="Lernvers"
                            filled
                        ></v-text-field>
                        <v-text-field
                            v-if="instanceData.hasArticlePlan"
                            :disabled="selectedPlan.learning_verse_ref && selectedPlan.learning_verse_ref !== ''"
                            v-model="selectedPlan.learning_verse_ref"
                            label="Lernversposition"
                            filled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
    
                  <v-col cols="12" sm="5">
                    <span class="form-header">Vers des Tages</span>
                    <v-divider class="mb-3"/>
    
                    <template v-if="!instanceData.hasDoubleVotd">
                      <v-row>
                        <v-col cols="6">
                          <v-autocomplete
                              v-model="votd_book"
                              :rules="[v => !!v || 'Buch ist ein Pflichtfeld']"
                              :items="votd_books"
                              item-value="number"
                              item-text="name"
                              label="Buch*"
                              required
                              filled
                          />
                        </v-col>
      
                        <v-col cols="6">
                          <v-autocomplete
                              v-model="translation"
                              :rules="[v => !!v || 'Übersetzung ist ein Pflichtfeld']"
                              :items="translations"
                              item-value="id"
                              item-text="name_full"
                              label="Übersetzung*"
                              required
                              filled
                          />
                        </v-col>
                      </v-row>
      
                      <v-row>
                        <v-col cols="4">
                          <v-autocomplete
                              v-model="votd_chapter"
                              :rules="[v => !!v || 'Kapitel ist ein Pflichtfeld']"
                              :items="votd_chapters"
                              item-text="chapter"
                              label="Kapitel*"
                              required
                              filled
                          />
                        </v-col>
      
                        <v-col cols="4">
                          <v-autocomplete
                              v-model="votd_verse"
                              :rules="[v => !!v || 'Vers ist ein Pflichtfeld']"
                              :items="votd_verses"
                              label="Vers*"
                              required
                              filled
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-autocomplete
                              v-model="votd_verse_end"
                              :rules="[v => (!v || v > this.votd_verse) || 'Vers Ende muss größer sein als der Beginn']"
                              :items="votd_verses"
                              label="Vers Ende"
                              clearable
                              filled
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="text-right py-0">
                          <v-btn
                              text
                              x-small
                              color="secondary"
                              dark
                              @click="showVotdRefOverwrite = !showVotdRefOverwrite"
                          >
                            Benutzerdefinierte Tagesvers Bibelstelle
                          </v-btn>
                          <v-text-field v-if="showVotdRefOverwrite"
                                        v-model="form_votd_reference_overwrite"
                                        label="Benutzerdefinierte Tagesvers Bibelstelle"
                                        filled
                          />
                        </v-col>
                      </v-row>
      
                      <v-row>
                        <v-col cols="12">
                          <small v-if="bibleServerURL">Übereinstimmung prüfen:
                            <a
                                target="_blank"
                                :href="bibleServerURL"
                            >
                              {{ decodeURI(bibleServerURL) }}
                            </a>
                          </small>
      
                          <v-textarea
                              v-model="form_votd"
                              :rules="[v => !!v || 'Tagesvers ist ein Pflichtfeld']"
                              label="Tagesvers*"
                              :counter="instanceData.verseLength"
                              rows="2"
                              auto-grow
                              filled
                              required
                          />
      
                          <v-checkbox
                            v-if="instanceData.hasVotdExcerpt"
                            v-model="form_votd_is_excerpt"
                            class="mt-0"
                            label="Tagesvers ist Auszug"
                          />
                        </v-col>
                      </v-row>
                    </template>
                    <template v-else>
                      <v-row>
                        <v-col cols="6">
                          <v-autocomplete
                              v-model="votd_book"
                              :rules="[v => !!v || 'Buch ist ein Pflichtfeld']"
                              :items="votd_books"
                              item-value="number"
                              item-text="name"
                              label="Buch*"
                              required
                              filled
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-autocomplete
                              v-model="votd_chapter"
                              :rules="[v => !!v || 'Kapitel ist ein Pflichtfeld']"
                              :items="votd_chapters"
                              item-text="chapter"
                              label="Kapitel*"
                              required
                              filled
                          />
                        </v-col>
                      </v-row>
      
                      <v-row>
                        <v-col cols="6">
                          <v-autocomplete
                              v-model="votd_verse"
                              :rules="[v => !!v || 'Vers ist ein Pflichtfeld']"
                              :items="votd_verses"
                              label="Vers*"
                              required
                              filled
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-autocomplete
                              v-model="votd_verse_end"
                              :rules="[v => (!v || v > this.votd_verse) || 'Vers Ende muss größer sein als der Beginn']"
                              :items="votd_verses"
                              label="Vers Ende"
                              clearable
                              filled
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="text-right py-0">
                          <v-btn
                              text
                              x-small
                              color="secondary"
                              dark
                              @click="showVotdRefOverwrite = !showVotdRefOverwrite"
                          >
                            Benutzerdefinierte Tagesvers Bibelstelle
                          </v-btn>
                          <v-text-field v-if="showVotdRefOverwrite"
                                        v-model="form_votd_reference_overwrite"
                                        label="Benutzerdefinierte Tagesvers Bibelstelle"
                                        filled
                          />
                        </v-col>
                      </v-row>
      
                      <v-row>
                        <v-col cols="12">
                          <small v-if="validReference">Übereinstimmung prüfen:
                            <a
                            target="_blank"
                            :href="`https://www.bibleserver.com/ELB/${validReference}`"
                            >
                            {{ decodeURI(`https://www.bibleserver.com/ELB/${validReference}`) }}
                            </a>
                          </small>
      
                          <v-textarea
                              v-model="form_votd"
                              :rules="[v => !!v || 'Tagesvers ist ein Pflichtfeld']"
                              label="Tagesvers ELB*"
                              :counter="instanceData.verseLength"
                              rows="2"
                              auto-grow
                              filled
                              required
                          />
      
                          <v-checkbox
                            v-if="instanceData.hasVotdExcerpt"
                            v-model="form_votd_is_excerpt"
                            class="mt-0"
                            label="Tagesvers ist Auszug"
                            hide-details
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <small v-if="validReference">Übereinstimmung prüfen:
                            <a
                            target="_blank"
                            :href="`https://www.bibleserver.com/NeÜ/${validReference}`"
                            >
                            {{ decodeURI(`https://www.bibleserver.com/NeÜ/${validReference}`) }}
                            </a>
                          </small>
      
                          <v-textarea
                              v-model="form_votd_alt"
                              :rules="[v => !!v || 'Tagesvers ist ein Pflichtfeld']"
                              label="Tagesvers NeÜ*"
                              :counter="instanceData.verseLength"
                              rows="2"
                              auto-grow
                              filled
                              required
                          />
      
                          <v-checkbox
                            v-if="instanceData.hasVotdExcerpt"
                            v-model="form_votd_alt_is_excerpt"
                            class="mt-0 mb-4"
                            label="Tagesvers ist Auszug"
                            hide-details
                          />
                        </v-col>
                      </v-row>
                    </template>
    
                    <span class="form-header">Thema</span>
                    <v-divider class="mb-3"/>
    
                    <v-row>
                      <v-col cols="12">
                        <TopicSelection
                          v-model="topic"
                          :disabled="selectedPlan.content_topic ? true : false"
                        />
                      </v-col>
    
                      <v-col cols="12" sm="6">
                        <v-autocomplete
                            v-model="eventTopic"
                            :items="eventTopics"
                            item-value="id"
                            item-text="name"
                            label="Anlass"
                            clearable
                            filled
                        />
                      </v-col>
    
                      <v-col cols="12" sm="6">
                        <v-autocomplete
                            v-model="event"
                            :items="events"
                            item-value="id"
                            item-text="name"
                            label="Ereignis"
                            clearable
                            filled
                        />
                      </v-col>
    
                      <v-col cols="12" v-if="!this.$store.getters.isEditor">
                        <v-textarea
                            v-model="comment_author"
                            label="Kommentar"
                            rows="2"
                            auto-grow
                            filled
                        />
                      </v-col>
    
                      <v-col cols="12" v-if="this.$store.getters.isEditor || this.$store.getters.isAdmin">
                        <v-autocomplete
                            v-model="author"
                            :items="authors"
                            item-value="id"
                            item-text="full_name"
                            label="Autor"
                            :clearable="selectedPlan.writer ? false : true"
                            filled
                            :disabled="selectedPlan.writer ? true : false"
                        />
                      </v-col>
    
                      <v-col cols="12" v-if="(this.$store.getters.isEditor || this.$store.getters.isAdmin) && this.$store.getters.currentProjectMeta.year < new Date().getFullYear()">
    
                        <v-menu
                          ref="dateMenu"
                          v-model="dateMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="DateTime.fromISO(date).toLocaleString()"
                              label="Datum"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="date"
                            :active-picker.sync="activePicker"
                            :min="`${this.$store.getters.currentProjectMeta.year}-01-01`"
                            :max="`${this.$store.getters.currentProjectMeta.year}-12-31`"
                            color="primary"
                            @change="saveDate"
                          />
                        </v-menu>
                      </v-col>
    
                      <v-col cols="12" class="text-right" v-if="!instanceData.hasSongs">
                        <v-divider class="mb-3"/>
                        <v-spacer/>
                        <v-btn color="primary" text @click="reset">Zurücksetzen</v-btn>
                        <v-btn color="primary" text @click="close">Schließen</v-btn>
                        <v-btn color="primary" elevation="0" @click="submit">Speichern</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
        </v-tab-item>
        <v-tab-item
          v-if="instanceData.hasSongs"
        >
          <SongSelection 
            :song="used_song_text_data.gl_number ? used_song_text_data : null"
            v-model="song_text" 
            @songSelected="updateSong"
          />
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions v-if=instanceData.hasSongs>
        <v-spacer/>
        <v-btn color="primary" text @click="reset">Zurücksetzen</v-btn>
        <v-btn color="primary" text @click="close">Schließen</v-btn>
        <v-btn color="primary" elevation="0" @click="submit">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory"
import {
  TiptapVuetify,
  Bold,
  Italic,
  History
  /*
  BulletList,
  OrderedList,
  ListItem,
  Paragraph
  */,
} from "tiptap-vuetify"
import textModifyMixin from "@/mixins/textModifyMixin"
import bibleRefMixin from "@/mixins/bibleRefMixin"
import projectMixin from "@/mixins/projectMixin"
import DateTime from "luxon/src/datetime.js"

import TopicSelection from "../articles/TopicSelection"
import SongSelection from "../articles/SongSelection"

const BibleTranslationRepository = RepositoryFactory.get("bible_translations")
const TopicsRepository = RepositoryFactory.get("topics")
const EventsRepository = RepositoryFactory.get("events")
const ArticlesRepository = RepositoryFactory.get("articles")
const BibleRepository = RepositoryFactory.get("bible")
const UsersRepository = RepositoryFactory.get("users")
const ReadingPlanRepository = RepositoryFactory.get("readingPlans")

export default {
  name: "DialogArticleNew",
  components: { TiptapVuetify, TopicSelection, SongSelection},
  props: {
    dialog: Boolean,
    // author: Number,
    selectedPlan: {
      type: Object,
      default() {
        return { author: false, topic: false }
      }
    }
  },
  mixins: [textModifyMixin, bibleRefMixin, projectMixin],
  data() {
    return {
      loading: false,
      newText: '',
      translations: [],
      translation: null,
      votd_alt_bible_translation: null,
      workTitle: null,
      topics: [],
      subTopics: [],
      topic: null,
      events: [],
      event: null,
      authors: [],
      author: null,
      eventTopics: [],
      eventTopic: null,
      votd_book: null,
      votd_books: [],
      votd_chapter: null,
      votd_chapters: [],
      votd_verse: null,
      votd_verse_end: null,
      votd_verses: [],
      valid: true,
      form_title: '',
      form_text: '',
      form_votd: '',
      form_votd_alt: '',
      form_votd_reference_overwrite: '',
      form_votd_is_excerpt: '',
      form_votd_alt_is_excerpt: '',
      form_question: '',
      form_hint: '',
      form_recommended_bible_ref: '',
      form_learning_verse: '',
      form_learning_verse_ref: '',
      comment_author: '',
      tiptapEditor: null,
      tiptapIsValid: false,
      tiptapText: '',
      tiptapIsEmpty: false,
      activePicker: null,
      date: null,
      project: null,
      dateMenu: false,
      extensions: [
        Bold,
        Italic,
        History,
        // ListItem,
        // BulletList,
        // OrderedList,
        // Paragraph,
      ],
      questionHintExtensions: [
        Bold,
        Italic
      ],
      showVotdRefOverwrite: false,
      DateTime,
      TopicSelection,
      readingPlan: {},
      tab: 0,
      used_song_text: 0,
      used_song_text_data: {},
      song_text: ""
    }
  },
  created() {
    if (this.dialog) {
      this.fetchTranslations();
      this.fetchTopics();
      this.fetchEvents();
      this.fetchEventTopics();
      this.fetchBooks();
      if(this.instanceData.hasReadingPlan) {
        this.getReadingPlan();
      } else if (this.instanceData.recommendedBibleRefInArticlePlannings) {
        this.form_recommended_bible_ref = this.selectedPlan.recommended_bible_reference
      }
    }
    // this.date = this.$store.getters.currentProjectMeta.year + "-01-01"
  },
  methods: {
    async fetchTranslations() {
      this.loading = true;
      const {data} = await BibleTranslationRepository.get();
      this.translations = data;
      this.loading = false;
    },
    async fetchUsers() {
      this.loading = true;
      const {data} = await UsersRepository.get();
      this.authors = data;
      this.loading = false;
    },
    async fetchTopics() {
      this.loading = true
      const { data: topicsData } = await TopicsRepository.get()
      this.topics = topicsData
      if(this.instanceData.hasSubTopics) {
        const { data: subTopicsData } = await TopicsRepository.getSubTopics()
        this.subTopics = subTopicsData
      }
      this.loading = false
    },
    async fetchEvents() {
      this.loading = true;
      const {data} = await EventsRepository.get();
      this.events = data;
      this.loading = false;
    },
    async fetchEventTopics() {
      this.loading = true;
      const {data} = await EventsRepository.getEventTopics();
      this.eventTopics = data;
      this.loading = false;
    },    
    setSelectedTopic() {
      let planTopics =
        this.selectedPlan.content_topic_data.topic +
        (this.selectedPlan.content_topic_data.sub_topic
          ? "-" + this.selectedPlan.content_topic_data.sub_topic
          : "")

      if (
        this.topics.filter((topic) => topic.identifier == planTopics).length > 0
      ) {
        this.topic = this.topics.filter(
          (topic) => topic.identifier == planTopics
        )[0]
      } else {
        this.topic = this.subTopics.filter(
          (subTopic) => subTopic.identifier == planTopics
        )[0]
      }
    },
    async getReadingPlan() {
      this.loading = true;
      const { data: readingPlanData } = await ReadingPlanRepository.getByDate(this.date)
      this.readingPlan = readingPlanData
      this.form_recommended_bible_ref = readingPlanData.recommended_bible_reference
      this.loading = false
    },
    async submit() {
      this.tiptapText = this.htmlToText(this.tiptapEditor.getHTML())

      this.tiptapIsEmpty = this.tiptapText === '';
      this.tiptapIsValid = !this.tiptapIsEmpty;
      if (!(this.$store.getters.isEditor || this.$store.getters.isAdmin)) {
        this.author = false;
        this.form_text = this.replaceSpecialChars(this.form_text);
      }
      if (this.form_text) this.form_text = this.replaceSpecialChars(this.form_text);
      if (this.form_question) this.form_question = this.replaceSpecialChars(this.form_question);
      if (this.form_hint) this.form_hint = this.replaceSpecialChars(this.form_hint);
      if (this.form_recommended_bible_ref) this.form_recommended_bible_ref = this.replaceSpecialChars(this.form_recommended_bible_ref);
      if (this.form_votd) this.form_votd = this.replaceSpecialChars(this.form_votd);
      if (this.form_title) this.form_title = this.replaceSpecialChars(this.form_title);

      if (this.$refs.form.validate() && this.tiptapIsValid && this.song_text.length <= 120) {
        const {data} = await ArticlesRepository.add(
            this.form_title,
            this.form_text,
            this.form_votd,
            this.votd_book + ';' + this.votd_chapter + ';' + this.votd_verse + ';' + (this.votd_verse_end === undefined || this.votd_verse_end == null ? "" : this.votd_verse_end),
            this.translation,
            this.topic?.identifier ? this.topic.identifier : false,
            this.selectedPlan.content_topic,
            this.form_question,
            this.form_hint,
            this.eventTopic,
            this.event,
            this.form_recommended_bible_ref,
            this.selectedPlan.learning_verse,
            this.selectedPlan.learning_verse_ref,
            this.form_votd_reference_overwrite,
            this.form_votd_is_excerpt,
            this.author,
            this.comment_author,
            this.project,
            this.date,
            this.selectedPlan.reused_article,
            this.selectedPlan.id,
            false,
            this.used_song_text,
            this.song_text,
            this.selectedPlan.assigned_editor,
            this.form_votd_alt,
            this.votd_book + ';' + this.votd_chapter + ';' + this.votd_verse + ';' + (this.votd_verse_end === undefined || this.votd_verse_end == null ? "" : this.votd_verse_end),
            this.form_votd_alt_is_excerpt,
            this.translation
            // this.$store.getters.currentProjectMeta.year < new Date().getFullYear() ? this.$store.state.currentProjectMeta.id : false,
            // this.$store.getters.currentProjectMeta.year < new Date().getFullYear() ? this.date : false
        );
        await this.$store.dispatch('setMessage', "Der Eintrag wurde erfolgreich eingereicht.");
        this.$emit('saved', data);
        this.close();
        this.reset();
      } else {
        await this.$store.dispatch('setMessage', "Speichern nicht möglich. Es fehlen noch Pflichtangaben.");
      }
    },
    reset() {
      this.$refs.form.reset()
      this.used_song_text_data = {}
      this.used_song_text = 0
      this.song_text = ""
      this.form_text = '';
    },
    async fetchBooks() {
      this.loading = true;
      const {data} = await BibleRepository.getBooks();
      this.votd_books = data;
      this.loading = false;
    },
    async fetchChapters(book_id) {
      this.loading = true;
      const {data} = await BibleRepository.getChaptersByBook(book_id);
      this.votd_chapters = data;
      if (this.votd_chapter == null || this.votd_chapter > this.votd_chapters.length) {
        this.votd_chapter = null;
        this.votd_verses = [];
        this.votd_verse = null;
      } else {
        this.fill_verse_options(this.votd_chapter)
      }
      this.loading = false;
    },
    fill_verse_options(chapter_id) {
      this.votd_verses = [];
      let versesAmount = this.votd_chapters.find(obj => {
        return obj.chapter === chapter_id;
      }).verse_count;
      for (let i = 1; i <= versesAmount; i++) {
        this.votd_verses.push(i);
      }
      if (this.votd_verse != null && this.votd_verse > versesAmount) {
        this.votd_verse = null;
      }
      if (this.votd_verse_end != null && this.votd_verse_end > versesAmount) {
        this.votd_verse_end = null;
      }
    },
    async fetchEventDaysByTopic(topic_id) {
      this.loading = true;
      const {data} = await EventsRepository.getDays(topic_id);
      this.events = data;
      this.loading = false;
    },
    onInitTiptap(e) {
      this.tiptapEditor = e.editor
    },
    onInitQuestionTiptap(e) {
      this.questionTiptapEditor = e.editor
    },
    onInitHintTiptap(e) {
      this.hintTiptapEditor = e.editor
    },
    htmlToText(html) {
      return html.replace(/<[^>]*>?/gm, '')
    },
    close() {
      this.reset()
      this.$emit('close');
    },
    saveDate(date) {
      this.$refs.menu.save(date)
    },
    updateSong(newSong) {
      this.used_song_text = newSong.id
      this.used_song_text_data = newSong
    }
  },
  computed: {
    validReference: {
      get: function () {
        if (this.votd_book && this.votd_chapter && this.votd_verse) {
          let verseEnd = "";
          if (this.votd_verse_end !== undefined && this.votd_verse_end !== null && this.votd_verse_end > this.votd_verse) {
            verseEnd = this.votd_verse_end;
          }
          return this.parseBibleRef(this.votd_book + ';' + this.votd_chapter + ';' + this.votd_verse + ';' + verseEnd);
        } else {
          return false;
        }

      }
    },
    bibleServerURL: {
      get: function () {
        if (this.validReference && this.translation ) {
          let translationName = this.translations.find(x => x.id === this.translation).name;
          if (['andere'].includes(translationName))
            return false
          if (translationName == "ELB CSV") {
            let invalidReference = this.validReference
            invalidReference = invalidReference.replace(".", "")
            invalidReference = invalidReference.split(" ")
            let newValidReference = invalidReference.join("-")
            return encodeURI(
              `https://www.csv-bibel.de/bibel/${newValidReference}`
            )
          }
          return encodeURI(`https://www.bibleserver.com/${translationName}/${this.validReference}`)
        } else {
          return false
        }
      }
    }
  },
  watch: {
    async dialog(visible) {
      if (visible) {
        await this.fetchTranslations()
        await this.fetchTopics()
        await this.fetchEvents()
        await this.fetchEventTopics()
        await this.fetchBooks()
        if (this.selectedPlan?.content_topic) {
          this.setSelectedTopic()
          // this.topic = this.selectedPlan.topic
          this.author = this.selectedPlan.writer
          this.date = this.selectedPlan.assigned_date
          this.project = this.selectedPlan.project
        }
        if (this.$store.getters.isEditor || this.$store.getters.isAdmin) {
          this.fetchUsers();
        }
        if(this.date) {
          if(this.instanceData.hasReadingPlan) {
            this.getReadingPlan();
          } else if (this.instanceData.recommendedBibleRefInArticlePlannings) {
            this.form_recommended_bible_ref = this.selectedPlan.recommended_bible_reference
          }
        }
      }
    },
    votd_book(newValue) {
      if (newValue !== null) this.fetchChapters(newValue);
    },
    votd_chapter(newValue) {
      if (this.votd_chapters.length > 0 && this.votd_chapter != null) {
        this.fill_verse_options(newValue);
      } else if (this.votd_chapter == null) {
        this.votd_verse = null;
        this.votd_verse_end = null;
      }
    },
    eventTopic(new_value) {
      if (typeof new_value !== 'undefined') {
        this.fetchEventDaysByTopic(new_value);
      } else {
        this.fetchEvents();
      }
    }
  }
}
</script>

<style>
.text--warn {
  color: #c01500;
}

.form-header {
  color: #333333;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px;
}
</style>
