<template>
    <v-card class="flex flex-column ma-4">
        <v-toolbar color="primary" dark>
            <v-btn icon class="mr-4">
                <v-icon>mdi-magnify</v-icon>
            </v-btn>

            <v-toolbar-title>Zusammenfassung</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>

        <v-progress-linear
                v-model="progress"
                color="accent darken-1"
                height="25"
        >
            <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}% Abgeschlossen</strong>
            </template>
        </v-progress-linear>

        <v-card-text>
        <v-simple-table>
            <template v-slot:default>
                <tbody>
                <tr>
                    <td>Name</td>
                    <td>{{ project.name }}</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Jahr</td>
                    <td>{{ project.year }}</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Bearbeitbar</td>
                    <td></td>
                    <td>
                        <v-icon v-if="project.editable" color="green darken-1">
                            mdi-check-circle
                        </v-icon>
                        <v-icon v-else color="primary">mdi-close-circle</v-icon>
                    </td>
                </tr>
                <tr>
                    <td>Abgeschlossen</td>
                    <td></td>
                    <td>
                        <v-icon v-if="project.finalized" color="green darken-1">
                            mdi-check-circle
                        </v-icon>
                        <v-icon v-else color="primary">mdi-close-circle</v-icon>
                    </td>
                </tr>
                <tr>
                    <td>Beiträge im Projekt</td>
                    <td>
                        {{ stats.project_article_count }}

                    </td>
                    <td>
                        <span v-if="stats.project_article_count >= stats.project_day_count">
                            <v-icon color="green darken-1">
                                mdi-check-circle
                            </v-icon>
                        </span>
                        <span v-else>({{ stats.project_day_count - stats.project_article_count }} fehlen)</span>
                    </td>
                </tr>
                <tr>
                    <td>Status "Überarbeitet"</td>
                    <td>
                        {{ stats.project_article_revised }}
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>Status "Freigegeben"</td>
                    <td>
                        {{ stats.project_article_approved }}
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>Status "Finalisiert"</td>
                    <td>
                        {{ stats.project_article_finalized }}
                    </td>
                    <td>
                        <span v-if="stats.project_article_finalized >= stats.project_day_count">
                            <v-icon color="green darken-1">mdi-check-circle</v-icon>
                        </span>
                        <span v-else>({{ stats.project_day_count - stats.project_article_finalized }} fehlen)</span>
                    </td>
                </tr>
                <tr>
                    <td>Beiträge mit Datum</td>
                    <td>
                        {{ stats.project_article_date_assigned }}
                    </td>
                    <td>
                        <span v-if="stats.project_article_date_assigned === stats.project_day_count">
                            <v-icon color="green darken-1">mdi-check-circle</v-icon>
                        </span>
                        <span v-else>({{ stats.project_day_count - stats.project_article_date_assigned }} fehlen)</span>
                    </td>
                </tr>
                <tr>
                    <td>Themenbereiche</td>
                    <td>
                        <span v-if="topics.length === 0 || (topics.length === 1 && topics[0].topic_count === 0)">
                            0
                        </span>
                        <span v-else>{{ stats.topics.length }}</span>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>Nicht zugeordnete Beiträge</td>
                    <td>
                        {{ article_stats.count_unused }}
                    </td>
                    <td></td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
        </v-card-text>
        <v-card-actions v-if="$store.getters.isAdmin">
            <v-btn
                color="primary" 
                @click="emailDialog = true"
                block
            >
                Emails versenden
            </v-btn>
            <v-dialog
                width="50%"
                v-model="emailDialog"
            >
                <v-card>
                    <v-card-title>
                        Emails versenden
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="9">
                                <div v-if="emailOptions.length > 1" class="mb-4">
                                    Wählen Sie aus welche Emails versendet werden sollen:
                                    <v-select v-model="emailType" :items="emailOptions" hide-details/>
                                    {{ emailTypeInfo }}
                                </div>
                                <div v-else class="mb-4">
                                    {{ emailTypeInfo }}
                                </div>
                                <v-text-field v-model="emailSubject" label="Betreff" :disabled="!emailEditEnabled"/>
                                <v-textarea v-model="emailMessage" label="Nachricht" :disabled="!emailEditEnabled"/>
                                <v-checkbox v-model="emailEditEnabled" label="Nachricht und Betreff bearbeiten"/>
                            </v-col>
                            <v-col cols="3">
                                <v-card
                                    outlined
                                    tile
                                    height="100%"
                                    max-height="416px"
                                    class="overflow-auto"
                                    :loading="emailDialogLoading"
                                >
                                    <v-card-title  class="pb-0 grey--text">
                                        Empfänger:
                                    </v-card-title>

                                    <v-list class="pt-0" dense>
                                        <v-list-item v-for="recipient in emailRecipients" :key="recipient.email" class="grey--text">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ recipient.first_name }} {{ recipient.last_name }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle v-html="recipient.email" />
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                    
                                    <v-card-text class="text-center">
                                        <template v-if="emailRecipients.length == 0">
                                            <div class="d-flex justify-center">
                                                <v-icon size="140">mdi-email-off-outline</v-icon>
                                            </div>
                                            <div class="d-flex justify-center text-h6">
                                                Die Ausgewählte Gruppe enthält keine Empfänger.
                                            </div>
                                        </template>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn 
                            color="error" 
                            plain
                            @click="emailDialog = false"
                        >
                            abbrechen
                        </v-btn>
                        <v-spacer />
                        <v-btn
                            color="primary"
                            @click="sendMail"
                            :disabled="true"
                            :loading="emailDialogLoading"
                        > 
                        <!-- renable button -->
                            Emails versenden
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card-actions>
    </v-card>
</template>

<script>
import projectMixin from "@/mixins/projectMixin"

import {RepositoryFactory} from "@/repositories/RepositoryFactory";
const EmailRepository = RepositoryFactory.get('email');

export default {
    name: "WidgetProjectSummary",
    mixins: [projectMixin],
    props: {
        project: Object,
        topics: Array,
        progress: Number,
        stats: Object,
        article_stats: Object,
    },
    data() {
        return {
            emailDialog: false,
            emailType: '',
            emailOptions: [
                {value: 'all', text: 'Projekt start (alle Autoren)'},
            ],
            emailTypeInfo: "",
            emailSubject: "",
            emailMessage: "",
            emailRecipients: [],
            emailEditEnabled: false,
            emailDialogLoading: false
        }
    },
     async mounted() {
    },
    methods: {
        async sendMail() {
            this.emailDialogLoading = true
            console.log("Betreff:", this.emailSubject, "Nachricht:", this.emailMessage)
            switch(this.emailType) {
                case 'all':
                    await EmailRepository.sendToAllActiveAuthors(this.emailSubject, this.emailMessage)
                        .then(
                            this.$store.dispatch('setMessage', 'Emails wurden versendet.')
                        ).catch(err => {
                                this.$store.dispatch('setMessage', `Etwas ist schiefgelaufen. ${err}`)
                            }
                        )
                    break
                case 'start':
                    await EmailRepository.sendToAllPlannedAuthors(this.project.id, this.emailSubject, this.emailMessage)
                        .then(
                            this.$store.dispatch('setMessage', 'Emails wurden versendet.')
                        ).catch(err => {
                                this.$store.dispatch('setMessage', `Etwas ist schiefgelaufen. ${err}`)
                            }
                        )
                    break
                case 'missing':
                    await EmailRepository.sendToAllPlannedWithoutEdits(this.project.id, this.emailSubject, this.emailMessage)
                        .then(
                            this.$store.dispatch('setMessage', 'Emails wurden versendet.')
                        ).catch(err => {
                                this.$store.dispatch('setMessage', `Etwas ist schiefgelaufen. ${err}`)
                            }
                        )
                    break
                case 'incomplete':
                    await EmailRepository.sendToAllPlannedWithUnfinished(this.project.id, this.emailSubject, this.emailMessage)
                        .then(
                            this.$store.dispatch('setMessage', 'Emails wurden versendet.')
                        ).catch(err => {
                                this.$store.dispatch('setMessage', `Etwas ist schiefgelaufen. ${err}`)
                            }
                        )
                    break
            }
            this.emailDialogLoading = false
        }
    },
    watch: {
        async emailDialog(val) {
            this.emailDialogLoading = true
            if (val) {
                if (this.instanceData.hasArticlePlan) {
                    this.emailOptions.push(
                        {value: 'start', text: 'Projekt start (nach Plan)'},
                        {value: 'missing', text: 'Fehlende Autoren'},
                        {value: 'incomplete', text: 'Nicht komplett'}
                    )
                    this.emailType = 'start'
                } else {
                    this.emailType = 'all'
                }
            }
            this.emailDialogLoading = false
        },
        async emailType(val) {
            this.emailDialogLoading = true
            switch(val) {
                case 'all':
                    this.emailTypeInfo = 'Es wird eine Mail an alle aktiven Autoren gesendet.'
                    this.emailSubject = 'Ein neuer Kalender steht an!'
                    this.emailMessage = 'test'
                    var {data: activeAuthorsData} = await EmailRepository.getAllActiveAuthors()
                    this.emailRecipients = activeAuthorsData
                    break
                case 'start':
                    this.emailTypeInfo = 'Es wird eine Mail an alle Autoren, die im Plan für das aktive Projekt eingetragen sind, gesendet.'
                    this.emailSubject = 'Das neue Projekt startet jetzt!'
                    this.emailMessage = 'Projekttest'
                    var {data: plannedAuthorsData} = await EmailRepository.getAllPlannedAuthors(this.project.id)
                    this.emailRecipients = plannedAuthorsData
                    break
                case 'missing':
                    this.emailTypeInfo = 'Es wird eine Mail an alle Autoren, die noch kein Artikel eingereicht haben, gesendet.'
                    this.emailSubject = 'Keine Beiträge'
                    this.emailMessage = 'Fehlendtest'
                    var {data: plannedAuthorsWithoutEditsData} = await EmailRepository.getAllPlannedWithoutEdits(this.project.id)
                    this.emailRecipients = plannedAuthorsWithoutEditsData
                    break
                case 'incomplete':
                    this.emailTypeInfo = 'Es wird eine Mail an alle Autoren, die noch offene Artikel in der Planung haben, gesendet.'
                    this.emailSubject = 'Es fehlen weitere Beiträge'
                    this.emailMessage = 'Plantest'
                    var {data: plannedAuthorsWithUnfinished} = await EmailRepository.getAllPlannedWithUnfinished(this.project.id)
                    this.emailRecipients = plannedAuthorsWithUnfinished
                    break
                default:
                    console.log(val)
            }
            this.emailDialogLoading = false
        }
    }
}
</script>

<style scoped>

</style>
